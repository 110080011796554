<template>
  <b-modal id="crop-modal" centered hide-header hide-footer @hidden="resetCrop">
    <VueCropper
      ref="cropper"
      v-show="image.selectedFile"
      :src="image.selectedFile"
      :aspectRatio="1 / 1"
      :cropBoxResizable="false"
    />
    <div class="mt-2 text-right">
      <b-button variant="primary" @click="cropImage">Recortar imagem</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'CropperImage',
  props: {
    selectedFile: [File, Object]
  },
  data: () => ({
    image: {
      selectedFile: '',
      cropedImage: '',
      cropedBlob: ''
    }
  }),
  methods: {
    async cropImage() {
      this.image.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
        this.$emit('croped-image', blob)
        this.image.cropedBlob = blob
      })
      this.$emit('update-image', this.image)
      this.$bvModal.hide('crop-modal')
    },
    resetCrop() {
      this.image.selectedFile = ''
      this.$emit('reset-cropped-image')
    }
  },
  watch: {
    selectedFile: {
      handler(value) {
        if (!value?.name) return
        const reader = new FileReader()
        reader.onload = event => {
          this.image.selectedFile = event.target.result
        }
        reader.readAsDataURL(value)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#crop-modal {
  .cropper-crop-box,
  .cropper-view-box {
    border-radius: 50%;
  }
  .cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
  }
  .cropper-face {
    background-color: inherit !important;
  }

  .cropper-dashed,
  .cropper-line {
    display: none !important;
  }
  .cropper-view-box {
    outline: inherit !important;
  }

  .cropper-point.point-se {
    top: calc(85% + 1px);
    right: 14%;
  }
  .cropper-point.point-sw {
    top: calc(85% + 1px);
    left: 14%;
  }
  .cropper-point.point-nw {
    top: calc(15% - 5px);
    left: 14%;
  }
  .cropper-point.point-ne {
    top: calc(15% - 5px);
    right: 14%;
  }
}
</style>
